import React from "react";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
import icon from "../assets/sustenance-logo.ico";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";

export default () => {
  return (
    <AboutSection>
      <Nav>
        <a href="/">
          <img src={icon} alt="App logo" />
        </a>

        <FancyLink offset="100" to="/">
          Home
        </FancyLink>
      </Nav>
      <Heading>Who we are...</Heading>

      <Content>
        Sustenance Nigeria Limited is a Private Limited Liability company
        incorporated on 14th September, 1990 as a multifarious concern. Being a
        company of multifarious concern, the organization is into livestock
        production and fish farming, it also serves as importer, exporter,
        supplier and general contractor involve in minerals, and Liquefied
        Petroleum Gas (LPG).
      </Content>
      <br />
      <Content>
        In the 1990s the company became a registered Contactor and Supplier,
        obtained licence to possess (or purchase) minerals, and also established
        LPG plants. The minerals included in the lincence are: Topaz, Garnet,
        Sapphire, Emerald, Zircon, Amethyst, among others.
      </Content>
      <br />
      <Content>
        In the 2000s, the company started fish farming and poultry production,
        which will help to increase food production and food security in
        Nigeria.
      </Content>

      <br />
      <br />

      <Heading>Ownership Structure</Heading>
      <Content>
        Sustenance Limited is owned by Nigerians and our shareholders are:
      </Content>
      <br />

      <ol style={{ paddingLeft: "2%" }}>
        <li>Professor Iysa Ade Bello</li>
        <li>AbdulWahab Oyerinde</li>
        <li>Mustapha Adedotun</li>
      </ol>

      <br />
      <br />

      <Heading>Our Mission</Heading>

      <ol style={{ paddingLeft: "2%" }}>
        <li>
          <Content>
            To become the leading provider of livestock products with the aim of
            supporting the actualization of sustainable food production goal in
            Nigeria. This will be accomplished by providing maximum output of
            healthy life birds & fishes and processed farm animals, while
            exceeding customer's expectations.
          </Content>
        </li>
        <br />
        <li>
          <Content>
            To become top provider of import and export services as well as to
            become top Contractor.
          </Content>
        </li>
      </ol>

      <br />
      <br />

      <Heading>Our Objectives</Heading>

      <ul style={{ paddingLeft: "2%", listStyleType: "disc" }}>
        <li>
          <Content>
            Become a leading provider of fish and poultry products in Nigeria.
          </Content>
        </li>
        <li>
          <Content>
            Assist Nigeria to achieve sustainability in food production, by
            providing live and processed fish and poultry products at fair
            prices.
          </Content>
        </li>
        <li>
          <Content>
            Become a top rank manufacturer, importer, exporter and suppliers of
            selected minerals and Liquefied Petroleum Gas (LPG).
          </Content>
        </li>
        <li>
          <Content>Reach point of sustainable profitability.</Content>
        </li>
        <li>
          <Content>
            Give back to the society by creating employment and educating local
            farmers.
          </Content>
        </li>
      </ul>
      <div style={{height: "70px"}} ></div>
      <Footer />
    </AboutSection>
  );
};

const AboutSection = styled.div`
  color: white;
  text-align: left;
  /* height: 100vh; */
`;

const FancyLink = styled(NavLink)`
  text-decoration: none;
  font-size: 1rem;
  color: greenyellow;
  margin: auto 1.5rem;
  border: 1px solid greenyellow;
  padding: 0.1rem 0.8rem;
  border-radius: 0.1rem;
  font-weight: bold;
  margin-left: auto;
  outline: 0;

  &:hover {
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    background: greenyellow;
    color: darkred;
  }

  @media only screen and (max-width: 800px) {
    padding: 0.3rem 0.1rem;
    margin-left: auto;
    text-align: center;
  }
`;

const Nav = styled.nav`
  margin: 0 0px;
  border-top: 1px solid white;
  width: 97%;
  display: flex;
  justify-content: start;
  padding: 10px 0 0;

  img {
    height: 40px;
    margin: 10px 0;
  }
`;

const Heading = styled.h1`
  font-size: 30px;
`;

const Content = styled.p`
  font-size: 16px;
`;
