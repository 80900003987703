import React from "react";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { NavLink } from "react-router-dom";

import icon from "../assets/sustenance-logo.ico";

const NavSection = () => {
  return (
    <StyledNavSection>
      <a href="/">
        <img src={icon} alt="App logo" />
      </a>

      <Nav>
        <FancyLink offset="100" href="#About">
          About
        </FancyLink>

        <FancyLink offset="100" href="#Products">
          Products
        </FancyLink>

        <FancyLink offset="100" href="#Contact">
          Contact
        </FancyLink>

        <RightFancyLink>
          <FancyA to="/about">About Us</FancyA>
        </RightFancyLink>
      </Nav>
    </StyledNavSection>
  );
};

const StyledNavSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  img {
    height: 40px;
    margin: 10px 0;
  }
`;

const Nav = styled.nav`
  margin: 0 0px;
  border-top: 1px solid white;
  width: 97%;
  display: flex;
  justify-content: start;
  padding: 10px 0 0;
`;

const FancyLink = styled(AnchorLink)`
  text-decoration: none;
  font-size: 1rem;
  color: greenyellow;
  margin: auto 1.5rem;
  border: 1px solid greenyellow;
  padding: 0.1rem 0.8rem;
  border-radius: 0.1rem;
  font-weight: bold;
  outline: 0;

  &:hover {
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    background: greenyellow;
    color: darkred;
  }

  @media only screen and (max-width: 800px) {
    padding: 0.3rem 0.1rem;
    margin: 1rem 0.3rem;
    text-align: center;
  }
`;

const RightFancyLink = styled(FancyLink)`
  justify-self: right !important ;
  align-self: flex-end !important;
  margin-left: auto;
`

const FancyA = styled(NavLink)`
  color: white;
  justify-self: flex-end;
  color: greenyellow;
  align-self: stretch;

  &:hover {
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    background: greenyellow;
    color: darkred;
  }
`;

export default NavSection;
