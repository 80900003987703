import React from "react";
import styled from "styled-components";

export default () => {
  return (
    <ContactSection id="Contact" >
      <ContactDetails>
        <h1>Contact</h1>
        <p>35A Ogudu Road, Ojota, Lagos. Nigeria.</p>
        <p>1, Alhaja Rabiah Street, Islamic Area, Ede, Osun State. Nigeria.</p>
        <p>sustenancelimited@ gmail.com</p>
        <p>09092170934</p>
      </ContactDetails>

      <div>
        <img src="https://res.cloudinary.com/emkaydauda/image/upload/v1584222322/shopping-min.jpg" />
      </div>
    </ContactSection>
  );
};

const ContactSection = styled.section`
  color: white;

  display: flex;
  background: #382c34;
  margin: 85px 0;
  > div {
    width: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: 600px) {
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 40px;
  }

  p {
    font-size: 18px;
    margin: 15px 0;
  }

  @media only screen and (max-width: 600px) {
    p {
      width: 100%;
      font-size: 12px;
    }
  }
`;
