import React from "react";
import styled from "styled-components";

export default () => {
  return (
    <StyledProducts id="Products">
      <h1>What we offer at Sustenance Limited</h1>
      <p>You are assured of the highest quality always...</p>
      <div>
        {products.map(p => (
          <SingleProduct
            productImage={p.productImage}
            productDescription={p.productDescription}
            productTitle={p.productTitle}
          />
        ))}
      </div>
    </StyledProducts>
  );
};

const StyledProducts = styled.div`
  color: white;

  h1 {
    font-size: 34px;
    color: white;
    margin-top: 70px;
  }

  p {
    margin-bottom: 70px;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

const SingleProduct = ({ productImage, productTitle, productDescription }) => {
  return (
    <StyledSingleProduct>
      <img src={productImage} alt={productTitle} />
      <h3>{productTitle}</h3>
      <p>{productDescription}</p>
    </StyledSingleProduct>
  );
};

const StyledSingleProduct = styled.div`
  display: flex;
  flex-direction: column;
  width: 46%;
  background: darkgray;
  margin: 8px 0;
  border-radius: 8px;

  img {
    width: 100%;
    /* height: 305px; */
    max-height: 375px;
    min-height: 250px;
    border-radius: 8px;
  }

  h3 {
    font-size: 24px;
    margin: 40px 0;
  }

  p {
    text-align: center;
    width: 80%;
    align-self: center;
    font-size: 18px;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

const products = [
  {
    productImage: 'https://res.cloudinary.com/emkaydauda/image/upload/f_auto,q_auto/v1583913254/frozen.jpg',
    productTitle: "Frozen goods",
    productDescription:
      "One of our earliest additions to the growing catalog at Sustenance Limited. Our packaged and processed fish are rich in minerals and amino acids and all that good stuff you only hear about in nutrition classes."
  },
  
  {
    productImage: 'https://res.cloudinary.com/emkaydauda/image/upload/f_auto,q_auto/v1583913227/poultry.jpg',
    productTitle: "Healthy Poultry",
    productDescription:
      "Well fed and treated poultry birds on a food and medicine diet closely monitored by our consultant veterinarian. Our birds are healthy and unblemished."
  },
  {
    productImage: 'https://res.cloudinary.com/emkaydauda/image/upload/v1584222321/blue-rock-min.jpg',
    productTitle: "Rare Uncut Gems",
    productDescription:
      "Our wide range of gemstones will leave you completely dazzled, but in a good way. We supply stones like, but not limited to Topaz, Garnet, Sapphire, Emerald, Zircon and Amethyst."
  },
  {
    productImage: 'https://res.cloudinary.com/emkaydauda/image/upload/v1584222765/lpg-min.jpg',
    productTitle: "Pure Liquified Gas",
    productDescription:
      "LPG, or Liquified Petroleum Gas is another commodity you'll find at Sustenance Limited. Purchase per kilogram or in bulk as you demand. Send us a message and let's discuss your needs."
  },
];
