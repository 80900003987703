import React from 'react';
import styled from "styled-components";
import { Button } from "react-bulma-components";

export default () => {
    return <FooterDiv>
        <h3>Eager to do business with us?</h3>
        <a href="mailto:sustenancelimited@gmail.com" > <Button color='primary'>Send a Message</Button> </a>
    </FooterDiv>
}

const FooterDiv = styled.div`
    margin-top: 15px;
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    align-items: center;
    padding-bottom: 40px;

    h3 {
        font-size: 24px;
        margin: 12px 0;
        color: white;
    }

    a {
        width: 25%;
    }

    button {
        width: 100%;
    }

    @media only screen and (max-width: 600px) {
        button {
            width: 100%;
        }

        a {
            width: 50%;
        }
    }

`