import React from "react";
import "./App.css";
import "react-bulma-components/dist/react-bulma-components.min.css";
import NavSection from "./components/NavSection";
import Header from "./components/Header";
import Products from "./components/Products";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { Route } from "react-router-dom";
import AboutUs from "./components/AboutUs";

function App() {
  return (
    <div className="App">
      <Route exact path="/" component={Body} />
      <Route path='/about' component={AboutUs} />
    </div>
  );
}

const Body = () => {
  return (
    <>
      <NavSection />
      <Header />
      <Products />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
