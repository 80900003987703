import React from "react";
import styled from "styled-components";
export default () => {
  return (
    <HeaderSection id="About" >
      <HeaderDetails>
          <h1>Welcome to <br /> Sustenance <br /> Limited</h1>
          <h5>Your one stop shop for poultry, frozen goods and liquiefied gas. <br /> <br /> Hey, we also sell unique minerals, because what's  the next best thing after eating well? Looking good!!</h5>
      </HeaderDetails>
      <div>
          <img src='https://res.cloudinary.com/emkaydauda/image/upload/v1584222321/majesty-min.jpg' />
      </div>
    </HeaderSection>
  );
};

const HeaderSection = styled.div`
  display: flex;
  background: #382c34;
  margin: 15px 0;
  > div {
    width: 50%;

    img {
        width: 100%;
        height: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    
    > div {
      width: 100%;
    }
  }
`;

const HeaderDetails = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 10px;
  h1 {
      font-size: 80px;
      margin: 70px 0;
  }

  h5 {
      width: 40%;
      align-self: center;
      font-size: 15px;
  }

  @media only screen and (max-width: 600px) {    
    > h1 {
      font-size: 50px;
    }

    h5 {
      width: 80%;
    }
  }
`;
